.index-title {
  font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: $h1-font-size;
  font-weight: bold;
  color: lighten($brown, 12%);
  background-color: $orange;
}

.index-subtitle {
  font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: $h2-font-size;
  font-weight: bold;
  color: lighten($brown, 12%);
  background-color: $orange;
}

.index2-title {
  font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: $h3-font-size;
  font-weight: bold;
  color: lighten($brown, 12%);
  background-color: $orange;
}

.index2-subtitle {
  font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: $h5-font-size;
  font-weight: bold;
  color: lighten($brown, 12%);
  background-color: $orange;
}


.section-title {
  margin-bottom: $headings-margin-bottom * 2;
  //font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: $h4-font-size;
  font-weight: bold;
  line-height: $headings-line-height;
  color: lighten($brown, 12%);
  //background-color: $orange;
}

.menu-text {
  a {
    font-size: $h5-font-size;
    font-weight: bold;
    color: lighten($brown,12%);
    text-decoration: none;
    background-color: $orange;

    &:hover,
    &:focus {
      color: $orange;
      text-decoration: none;
      background-color: $bluep;
    }
  }
}

.sidebar-text {
  a {
    font-weight: bold;
    color: lighten($brown,12%);
    background-color: transparent;

    &:hover,
    &:focus {
      color: $orange;
      background-color: $bluep;
    }
  }
}

.menu-table {
  border: 0;
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        height: 70px;
        border: 0;
      }
    }
  }
  > thead > tr {
    > th,
    > td {
      border-bottom-width: 0;
    }
  }
}

.oi-zoom-in {
  color: $orange;
  opacity: 0;
}

.card:hover .photo-zoom {
  opacity: .3;
}

.card:hover .oi-zoom-in {
  opacity: 1;
}

